@import '../../style/colors.scss';
@import '../../style/global.scss';

header {
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 5rem;
	width: 100vw;
	font-size: 1.5rem;
	background-color: $navbar-background-color;
	color: $navbar-element-color;
	z-index: 1;

	@include max-media('tablet') {
		justify-content: flex-end;

		.responsive-nav {
			transform: none;
		}
	}

	nav {
		a {
			margin: 0 3.5rem;
			color: $navbar-element-color;
			text-decoration: none;

			i {
				transition: all 0.3s ease-out;
			}

			&:hover {
				color: $hover-color;

				svg {
					color: $hover-color;
				}
			}
		}

		@include max-media('tablet') {
			position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 3rem;
			background-color: $navbar-background-color;
			transition: 1s;
			transform: translateY(-100vh);
			z-index: 10;

			a {
				font-size: 3rem;
			}

			.nav-close-btn {
				position: absolute;
				top: 5%;
				right: 5%;
				margin: 0;
			}
		}

		@include max-media-landscape('tablet') {
			gap: 2rem;

			a {
				font-size: 2rem;
			}
		}
	}

	.nav-btn {
		cursor: pointer;
		background: transparent;
		border: none;
		outline: none;
		color: $navbar-element-color;
		visibility: hidden;
		opacity: 0;
		font-size: 1.8rem;

		@include max-media('tablet') {
			visibility: visible;
			opacity: 1;
			margin: 2rem;
			font-size: 3rem;
		}
	}
}
