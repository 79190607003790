@import '../../style/colors.scss';
@import '../../style/global.scss';

.timeline-page {
	background-color: $background-color-secondary;

	.heading {
		text-align: center;
		padding: 3rem 0 3rem 0;
		font-family: 'Coolvetica';
		font-weight: 400;
		line-height: 3.6rem;
		letter-spacing: 0.18rem;

		@include max-media('mobile') {
			font-size: 3rem;
			padding-bottom: 2rem;
		}

		h1 {
			color: $text-color-primary;
			font-size: 2.5rem;

			@include max-media('mobile') {
				font-size: 3rem;
			}

			.break {
				@include max-media('mobile') {
					content: ' ';
					display: block;
				}
			}
		}
	}

	.tag {
		height: 1.4rem;
		display: inline-flex;
		font-size: 0.8rem;
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		border-radius: 1rem;
		margin: 0.2rem 0.2rem 0 0;

		@include max-media('mobile') {
			height: 2rem;
			font-size: 1.4rem;
			margin: 0.25rem 0.25rem 0 0;
		}

		.tag-content {
			display: flex;
			align-items: center;
			user-select: none;
			padding: 0.5rem;

			@include max-media('mobile') {
				padding: 0.7rem;
			}
		}
	}

	#education {
		background-color: $background-color-primary;
	}

	.vertical-timeline {
		width: 95%;
		max-width: 1250px;
		margin: 0 auto;
		position: relative;
		padding: 2em 0 4em 0;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			width: 0.25rem;
			background: var(--line-color);
		}

		&::after {
			content: '';
			display: table;
			clear: both;
		}

		.vertical-timeline-element--work {
			.vertical-timeline-element-content {
				background: $content-background-color-secondary;
				color: $text-color-primary;
				border-radius: 0.5rem;

				.vertical-timeline-element-content-arrow {
					border-right: 0.5rem solid
						$content-background-color-secondary;
				}

				.vertical-timeline-element-title {
					color: $text-color-secondary;

					@include max-media('mobile') {
						font-size: 2.4rem;
					}
				}
				.vertical-timeline-element-subtitle {
					color: $text-color-primary;

					@include max-media('mobile') {
						font-size: 1.8rem;
					}
				}

				.vertical-timeline-element-description {
					font-size: 1rem;

					@include max-media('mobile') {
						font-size: 1.5rem;
					}
				}

				.citation {
					text-align: center;
					font-style: italic;
				}

				.vertical-timeline-element-date {
					color: $text-color-primary;

					@include max-media('mobile') {
						font-size: 1.2rem;
					}
				}
			}

			.vertical-timeline-element-icon {
				background: $content-background-color-secondary;
				color: $text-color-primary;
			}

			.tag {
				border: 0.1rem solid;
				background-color: $content-background-color-primary;
				color: $text-color-primary;
			}

			.australia {
				background-color: $australia-background-color;
				color: $australia-text-color;
			}
		}

		.vertical-timeline-element--education {
			.vertical-timeline-element-content {
				background: $content-background-color-primary;
				color: $text-color-primary;
				border-radius: 0.5rem;

				.vertical-timeline-element-content-arrow {
					border-right: 0.5rem solid $content-background-color-primary;
				}

				.vertical-timeline-element-title {
					color: $text-color-secondary;

					@include max-media('mobile') {
						font-size: 2.4rem;
					}
				}

				.vertical-timeline-element-subtitle {
					color: $text-color-primary;

					@include max-media('mobile') {
						font-size: 1.8rem;
					}
				}

				.vertical-timeline-element-description {
					font-size: 1rem;

					@include max-media('mobile') {
						font-size: 1.5rem;
					}
				}

				.vertical-timeline-element-date {
					color: $text-color-primary;

					@include max-media('mobile') {
						font-size: 1.2rem;
					}
				}
			}

			.vertical-timeline-element-icon {
				background: $content-background-color-primary;
				color: $text-color-primary;
			}

			.tag {
				border: 0.1rem solid;
				background-color: $content-background-color-secondary;
				color: $text-color-primary;
			}

			.australia {
				background-color: $australia-background-color;
				color: $australia-text-color;
			}
		}

		.vertical-timeline-element--star,
		.vertical-timeline-element--question-mark {
			.vertical-timeline-element-icon {
				background: $hover-color;
				color: $text-color-primary;
			}
		}
	}
}
