@import '../../style/colors.scss';
@import '../../style/global.scss';

.home-page {
	display: flex;
	width: 100%;
	background-color: $background-color-primary;

	@include max-media('tablet') {
		flex-wrap: wrap;
	}

	@include max-media-landscape('tablet') {
		flex-wrap: nowrap;
	}

	.text-zone {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;

		@include max-media('tablet') {
			flex-basis: 100%;
			height: 60vh;
		}

		@include max-media-landscape('tablet') {
			height: 100vh;
		}

		h1 {
			color: $text-color-primary;
			font-size: 4rem;
			line-height: 3.6rem;
			margin: 0;
			font-family: 'Coolvetica';
			font-weight: 400;

			@include max-media-landscape('tablet') {
				font-size: 3rem;
				line-height: 3rem;
			}
		}

		h2 {
			color: $text-color-secondary;
			margin-top: 1.5rem;
			font-weight: 400;
			font-size: 1.4rem;
			font-family: sans-serif;
			letter-spacing: 0.18rem;
			animation: fadeIn 1s 4.8s backwards;

			@include max-media-landscape('tablet') {
				font-size: 1rem;
				letter-spacing: 0.1rem;
			}
		}

		.flat-button {
			color: $hover-color;
			font-size: 1.6rem;
			font-weight: 400;
			letter-spacing: 0.28rem;
			font-family: sans-serif;
			text-decoration: none;
			padding: 0.7rem 1rem;
			border: 0.1rem solid $hover-color;
			margin-top: 1.6rem;
			float: left;
			animation: fadeIn 1s 6s backwards;
			white-space: nowrap;

			@include max-media-landscape('tablet') {
				font-size: 1.2rem;
				letter-spacing: 0.2rem;
			}

			&:hover {
				background-color: $hover-color;
				color: $background-color-primary;
			}
		}
	}

	.logos-container {
		flex: 1;
		animation: fadeIn 1s 6s backwards;
		height: 100vh;
		position: relative;

		@include max-media('tablet') {
			flex-basis: 100%;
			height: 40vh;
		}

		@include max-media-landscape('tablet') {
			height: 100vh;
		}

		.svg-logo {
			width: 4rem;
			height: 4rem;
			position: absolute;
			left: 45%;
			top: 50%;
			animation: spin-around 15s infinite linear;

			@include max-media('tablet') {
				top: 25%;
			}

			@include max-media-landscape('tablet') {
				width: 3rem;
				height: 3rem;
				animation: spin-around-tablet 15s infinite linear;
				top: 50%;
			}
		}
	}
}

$degree-0-a: 1;
$degree-0-b: 0;
$degree-22-dot-5-a: 0.92;
$degree-22-dot-5-b: 0.38;
$degree-45: 0.7;
$toInterger: 100;
$factor: 100 * 0.15rem;
$factor-tablet: 100 * 0.08rem;

@keyframes spin-around {
	0% {
		transform: translate(calc($degree-0-a * $factor), $degree-0-b); // 1, 0
	}

	6% {
		transform: translate(
			calc($degree-22-dot-5-a * $factor),
			calc($degree-22-dot-5-b * $factor)
		); // 0.92, 0.38
	}

	12% {
		transform: translate(
			calc($degree-45 * $factor),
			calc($degree-45 * $factor)
		); // 0.7, 0.7
	}

	18% {
		transform: translate(
			calc($degree-22-dot-5-b * $factor),
			calc($degree-22-dot-5-a * $factor)
		); // 0.38, 0.92
	}

	25% {
		transform: translate($degree-0-b, calc($degree-0-a * $factor)); // 0, 1
	}

	31% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-b * $factor),
			calc($degree-22-dot-5-a * $factor)
		); // -0.38, 0.92
	}

	37% {
		transform: translate(
			calc(-1 * $degree-45 * $factor),
			calc($degree-45 * $factor)
		); // -0.7, 0.7
	}

	43% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-a * $factor),
			calc($degree-22-dot-5-b * $factor)
		); // -0.92, 0.38
	}

	50% {
		transform: translate(
			calc(-1 * $degree-0-a * $factor),
			$degree-0-b
		); // -1, 0
	}

	56% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-a * $factor),
			calc(-1 * $degree-22-dot-5-b * $factor)
		); // -0.92, -0.38
	}

	62% {
		transform: translate(
			calc(-1 * $degree-45 * $factor),
			calc(-1 * $degree-45 * $factor)
		); // -0.7, -0.7
	}

	68% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-b * $factor),
			calc(-1 * $degree-22-dot-5-a * $factor)
		); // -0.38, -0.92
	}

	75% {
		transform: translate(
			$degree-0-b,
			calc(-1 * $degree-0-a * $factor)
		); // 0, -1
	}

	81% {
		transform: translate(
			calc($degree-22-dot-5-b * $factor),
			calc(-1 * $degree-22-dot-5-a * $factor)
		); // 0.38, -0.92
	}

	87% {
		transform: translate(
			calc($degree-45 * $factor),
			calc(-1 * $degree-45 * $factor)
		); // 0.7, -0.7
	}

	93% {
		transform: translate(
			calc($degree-22-dot-5-a * $factor),
			calc(-1 * $degree-22-dot-5-b * $factor)
		); // 0.92, -0.38
	}

	100% {
		transform: translate(calc($degree-0-a * $factor), $degree-0-b); // 1, 0
	}
}

@keyframes spin-around-tablet {
	0% {
		transform: translate(
			calc($degree-0-a * $factor-tablet),
			$degree-0-b
		); // 1, 0
	}

	6% {
		transform: translate(
			calc($degree-22-dot-5-a * $factor-tablet),
			calc($degree-22-dot-5-b * $factor-tablet)
		); // 0.92, 0.38
	}

	12% {
		transform: translate(
			calc($degree-45 * $factor-tablet),
			calc($degree-45 * $factor-tablet)
		); // 0.7, 0.7
	}

	18% {
		transform: translate(
			calc($degree-22-dot-5-b * $factor-tablet),
			calc($degree-22-dot-5-a * $factor-tablet)
		); // 0.38, 0.92
	}

	25% {
		transform: translate(
			$degree-0-b,
			calc($degree-0-a * $factor-tablet)
		); // 0, 1
	}

	31% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-b * $factor-tablet),
			calc($degree-22-dot-5-a * $factor-tablet)
		); // -0.38, 0.92
	}

	37% {
		transform: translate(
			calc(-1 * $degree-45 * $factor-tablet),
			calc($degree-45 * $factor-tablet)
		); // -0.7, 0.7
	}

	43% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-a * $factor-tablet),
			calc($degree-22-dot-5-b * $factor-tablet)
		); // -0.92, 0.38
	}

	50% {
		transform: translate(
			calc(-1 * $degree-0-a * $factor-tablet),
			$degree-0-b
		); // -1, 0
	}

	56% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-a * $factor-tablet),
			calc(-1 * $degree-22-dot-5-b * $factor-tablet)
		); // -0.92, -0.38
	}

	62% {
		transform: translate(
			calc(-1 * $degree-45 * $factor-tablet),
			calc(-1 * $degree-45 * $factor-tablet)
		); // -0.7, -0.7
	}

	68% {
		transform: translate(
			calc(-1 * $degree-22-dot-5-b * $factor-tablet),
			calc(-1 * $degree-22-dot-5-a * $factor-tablet)
		); // -0.38, -0.92
	}

	75% {
		transform: translate(
			$degree-0-b,
			calc(-1 * $degree-0-a * $factor-tablet)
		); // 0, -1
	}

	81% {
		transform: translate(
			calc($degree-22-dot-5-b * $factor-tablet),
			calc(-1 * $degree-22-dot-5-a * $factor-tablet)
		); // 0.38, -0.92
	}

	87% {
		transform: translate(
			calc($degree-45 * $factor-tablet),
			calc(-1 * $degree-45 * $factor-tablet)
		); // 0.7, -0.7
	}

	93% {
		transform: translate(
			calc($degree-22-dot-5-a * $factor-tablet),
			calc(-1 * $degree-22-dot-5-b * $factor-tablet)
		); // 0.92, -0.38
	}

	100% {
		transform: translate(
			calc($degree-0-a * $factor-tablet),
			$degree-0-b
		); // 1, 0
	}
}
