$breakpoints: (
	'mobile': 500px,
	'tablet': 768px,
	'tablet-horizontal': 900px,
	'laptop': 1024px,
	'laptop-m': 1280px,
	'laptop-l': 1440px,
);

@mixin max-media($_key) {
	@media screen and (max-width: map-get($breakpoints, $_key)) {
		& {
			@content;
		}
	}
}

@mixin max-media-landscape($_key) {
	@media screen and (orientation: landscape) and (max-width: map-get($breakpoints, $_key)) {
		& {
			@content;
		}
	}
}

html {
	font-size: 16px;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;

	@include max-media('laptop-m') {
		font-size: 16px;
	}

	@include max-media('laptop') {
		font-size: 12px;
	}

	@include max-media('tablet-horizontal') {
		font-size: 10px;
	}

	@include max-media('tablet') {
		font-size: 12px;
	}

	@include max-media('mobile') {
		font-size: 8px;
	}
}

.page {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

.container {
	position: relative;
	width: 100%;
}
