// Background
$background-color-primary: #102a43;
$background-color-secondary: #243b53;

// Content
$content-background-color-primary: #334e68;
$content-background-color-secondary: #486581;

// Hover
$hover-color: #ffc107;

// Text
$text-color-primary: #fff;
$text-color-secondary: #9fb3c8;

// Navbar
$navbar-background-color: #222222;
$navbar-element-color: #d9e2ec;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.2);
$shadow-hover-color: #f0f4f8;

// Australia
$australia-background-color: #ffcd00;
$australia-text-color: #00843d;

// // Global
// $primary-color: #ffd700;
// $background-color: #022c43;
// $text-color: white;
// $secondary-text-color: #8d8d8d;
// $heading-text-color: #ffd700;
// $hover-color: white;

// // Nav
// $nav-background-color: #181818;
// $nav-element-color: #4d4d4e;
// $nav-active-color: #ffd700;
// $nav-hover-color: #ffd700;

// // Home
// $home-background-color: #022c43;

// // Timeline
// $timeline-background-color-primary: #092838;
// $timeline-background-color-secondary: #022c43;

// $timeline-work-text-color-primary: #fff;
// $timeline-work-text-color-secondary: #303e49;
// $timeline-work-background-color-primary: rgb(33, 150, 243);
// $timeline-work-background-color-secondary: rgb(233, 30, 99);
// $timeline-work-background-color: $timeline-work-background-color-primary;
// $timeline-work-text-color: $timeline-work-text-color-primary;
// $timeline-work-icon-background-color: $timeline-work-background-color-primary;
// $timeline-work-icon-text-color: $timeline-work-text-color-primary;
// $timeline-work-tag-background-color: $timeline-work-background-color-secondary;
// $timeline-work-tag-text-color: $timeline-work-text-color-primary;
// $timeline-work-title-text-color: $timeline-work-text-color-secondary;
// $timeline-work-subtitle-text-color: $timeline-work-text-color-primary;

// $timeline-education-text-color-primary: #fff;
// $timeline-education-text-color-secondary: #303e49;
// $timeline-education-background-color-primary: rgb(233, 30, 99);
// $timeline-education-background-color-secondary: rgb(33, 150, 243);
// $timeline-education-background-color: $timeline-education-background-color-primary;
// $timeline-education-text-color: $timeline-education-text-color-primary;
// $timeline-education-icon-background-color: $timeline-education-background-color-primary;
// $timeline-education-icon-text-color: $timeline-education-text-color-primary;
// $timeline-education-tag-background-color: $timeline-education-background-color-secondary;
// $timeline-education-tag-text-color: $timeline-education-text-color-primary;
// $timeline-education-title-text-color: $timeline-education-text-color-secondary;
// $timeline-education-subtitle-text-color: $timeline-education-text-color-primary;

// $timeline-other-icon-background-color: rgb(16, 204, 82);
// $timeline-other-icon-text-color: #fff;

// // Projects
// $projects-background-color: #092838;

// // Card
// $card-background-color: rgb(33, 150, 243);
// $card-text-color: #fff;
// $card-button-background-color: rgb(33, 150, 243);
// $card-button-background-hover-color: #ffd700;
// $card-button-border-color: #ffd700;
// $card-button-border-hover-color: #ffd700;
// $card-button-text-color: #ffd700;
// $card-button-text-hover-color: $card-text-color;
