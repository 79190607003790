@import '../../style/colors.scss';
@import '../../style/global.scss';

.projects-page {
	background-color: $background-color-secondary;
	padding: 0 0 3rem 0;

	.heading {
		text-align: center;
		padding: 3rem 0 3rem 0;
		font-family: 'Coolvetica';
		font-weight: 400;
		line-height: 3.6rem;
		letter-spacing: 0.18rem;

		h1 {
			color: $text-color-primary;
			font-size: 2.5rem;

			@include max-media('mobile') {
				font-size: 3rem;
			}
		}
	}

	.cards-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}
