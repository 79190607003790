@import '../../style/colors.scss';
@import '../../style/global.scss';
@import 'animate.css';
@import '~loaders.css/src/animations/ball-grid-pulse.scss';

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../assets/fonts/helvetica-neu.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Coolvetica';
	src: url('../../assets/fonts/CoolveticaRg-Regular.woff2') format('woff2'),
		url('../../assets/fonts/CoolveticaRg-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

input,
textarea {
	font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
	display: none;
}

.loader-active {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	width: 10vh;
	height: 10vh;
	animation: fadeOut 1s 1s;
	animation-fill-mode: forwards;
}
