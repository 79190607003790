@import '../../style/colors.scss';
@import '../../style/global.scss';

.text-animate {
	display: inline-block;
	opacity: 0;
	animation: bounceIn;
	animation-duration: 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	min-width: 0.65rem;
}

.text-animate-hover {
	min-width: 0.65rem;
	display: inline-block;
	animation-fill-mode: both;

	&:hover {
		animation: rubberBand 1s;
		color: $hover-color;
	}
}

@for $i from 1 through 46 {
	.text-animate._#{$i} {
		animation-delay: #{$i / 10}s;
	}
}
