@import './style/colors.scss';
@import './style/global.scss';

body {
	margin: 0;
	padding: 0;
	font: 300 11px/1.4 'Helvetica Neue', sans-serif;
	color: $text-color-primary;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $background-color-primary;
	overflow: auto;
	display: block;
}
