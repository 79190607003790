@import '../../style/colors.scss';
@import '../../style/global.scss';

.card-container {
	width: 30%;
	overflow: hidden;
	box-shadow: 0 0.1rem 1rem $shadow-color;
	background-color: $content-background-color-secondary;
	color: $text-color-primary;
	margin: 1rem;
	border-radius: 0.5rem;

	@include max-media('tablet-horizontal') {
		width: 40%;
	}

	@include max-media('tablet') {
		width: 40%;
	}

	@include max-media('mobile') {
		width: 80%;
	}

	&:hover {
		animation: pulse 1s;
		box-shadow: 0 0 0.5rem $shadow-hover-color;
	}

	.card-image img {
		overflow: hidden;
		aspect-ratio: 16/9;
		width: 100%;

		object-fit: cover;
		object-position: left;
	}

	.card-content {
		margin: 0.5rem 1rem 1rem 1rem;
		padding: 0.8rem;

		h1 {
			font-size: 2rem;
			font-family: 'Coolvetica';
			font-weight: 400;
			line-height: 1.5rem;
			letter-spacing: 0.18rem;
			color: $text-color-secondary;

			@include max-media('mobile') {
				font-size: 2.4rem;
			}
		}

		.card-description-content {
			margin: 0;
			padding: 0;
			font-size: 1rem;

			@include max-media('mobile') {
				font-size: 1.5rem;
			}
		}

		.card-title {
			margin: 0 0 0.5rem 0;
		}

		.card-description {
		}
	}

	.card-button {
		display: flex;
		justify-content: center;

		button {
			background-color: $content-background-color-secondary;
			font-size: 1.2rem;
			font-weight: 400;
			letter-spacing: 0.14rem;
			font-family: sans-serif;
			text-decoration: none;
			padding: 0.7rem 1rem;
			margin: 0 0 1.2rem 0;
			border: 0.1rem solid $hover-color;
			white-space: nowrap;

			@include max-media('mobile') {
				font-size: 1.8rem;
			}

			a {
				text-decoration: none;
				color: $hover-color;
			}

			&:hover {
				background-color: $hover-color;

				a {
					color: $content-background-color-secondary;
				}
			}
		}
	}
}
