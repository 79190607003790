@import '../../style/colors.scss';
@import '../../style/global.scss';

.contact-page {
	background-color: $background-color-primary;

	.heading {
		text-align: center;
		padding: 3rem 0 3rem 0;
		font-family: 'Coolvetica';
		font-weight: 400;
		line-height: 3.6rem;
		letter-spacing: 0.18rem;

		h1 {
			color: $text-color-primary;
			font-size: 2.5rem;

			@include max-media('mobile') {
				font-size: 3rem;
			}
		}
	}

	.content-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.text-zone {
			width: 80%;
			overflow: hidden;
			box-shadow: 0 0.1rem 1rem $shadow-color;
			background-color: $content-background-color-secondary;
			color: $text-color-primary;
			margin: 1rem;
			border-radius: 0.5rem;
			margin: 0.5rem 1rem 1rem 1rem;
			padding: 1.5rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			.text-content {
				text-align: center;
				margin: 0;
				padding: 0;
				font-size: 1.5rem;

				@include max-media('mobile') {
					font-size: 1.8rem;
				}

				.email {
					font-weight: bold;
					cursor: pointer;
					color: $hover-color;
				}
			}

			.icons-zone {
				padding: 1.5rem 0;

				a {
					margin: 3.5rem;
					color: $navbar-element-color;
					text-decoration: none;
					font-size: 2rem;

					i {
						transition: all 0.3s ease-out;
					}

					&:hover {
						color: $hover-color;

						svg {
							color: $hover-color;
						}
					}
				}
			}
		}
	}
}
